<template>
  <div class="clients">
    <info-modal v-model="modal.show" :title="modal.message" @close="closeModal">
      <v-btn v-if="modal.info" color="primary" block @click="closeModal">{{ $t('clients.back_to_clients') }}</v-btn>
      <v-row v-else no-gutters>
        <v-col class="pr-2" cols="6">
          <v-btn class="font-weight-bold flex-grow-1" color="primary" block @click="selectAllInPage"
            >{{ $t('button.on_page') }} ({{ pageClientsCount }})</v-btn
          >
        </v-col>

        <v-col class="pl-2" cols="6">
          <v-btn class="font-weight-bold flex-grow-1" color="primary" block @click="selectGlobal"
            >{{ $t('button.all') }} ({{ clientsCount }})</v-btn
          >
        </v-col>
      </v-row>
    </info-modal>

    <entity-status-list :statuses="entityClientTypes">
      <template v-if="canDisplayActions">
        <div class="d-flex">
          <template v-if="!isShortTenantsType && canUpdateClients && !media.isMobile">
            <v-btn large class="mr-3" elevation="0" color="primary" :to="clientRoute">
              {{ $t('button.add_client') }}
              <v-icon right>mdi-plus</v-icon>
            </v-btn>
          </template>

          <clients-import-menu
            v-if="canUpdateClients && !media.isMobile"
            :is-processing="isProcessing"
            @import-clients="checkImportClients"
            @import-addresses="checkImportAddresses"
          />

          <clients-menu
            :type-client="typeClient"
            :page="page"
            :is-processing="isProcessing"
            :can-update="canUpdateClients"
            @export-clients="exportClients"
            @import-clients="checkImportClients"
            @import-addresses="checkImportAddresses"
            @archive-clients="archiveClients"
            @unarchive-clients="unarchive"
            @resent-email="resentEmail"
            @send-email="sendEmail"
            @select-all="selectAll"
          />
        </div>
      </template>
    </entity-status-list>

    <div class="d-flex justify-start justify-md-end mt-2">
      <import-status
        :status="importStatus.status"
        :url="importStatus.url"
        :error-document-name="errorDocumentName"
        :get-status="getImportStatus"
        @show-modal="showModal($t('import.process'))"
      />
    </div>

    <div v-if="isLoading" class="d-flex align-center justify-center" style="min-height:200px">
      <v-progress-circular indeterminate color="primary" />
    </div>

    <clients-missing-data v-else-if="isDataMissing" class="clients__missing-data" :type-client="typeClient" />

    <template v-else>
      <div v-if="media.isMobile" class="d-flex flex-column mb-1 mb-md-4">
        <mobile-sort class="mt-2" reverse :sort-list="clientsHeaders" :sort-by="activeHeader" :options.sync="options">
          <clients-menu
            :type-client="typeClient"
            :page="page"
            :is-processing="isProcessing"
            :can-update="canUpdateClients"
            @export-clients="exportClients"
            @import-clients="checkImportClients"
            @import-addresses="checkImportAddresses"
            @archive-clients="archiveClients"
            @unarchive-clients="unarchive"
            @resent-email="resentEmail"
            @send-email="sendEmail"
            @select-all="selectAll"
          />
        </mobile-sort>

        <div class="d-flex align-center mb-5">
          <mobile-search-menu
            :key="$route.name"
            v-model="queryModel"
            :placeholder="$t('label.client_search')"
            single-line
            full-width
            hide-details
          />

          <icon-button @click="showModalFilter">
            <v-icon>
              mdi-filter
            </v-icon>
          </icon-button>
          <clients-modal-filters v-model="showMobileFilter" :type-client="typeClient" :filters.sync="tableFilter" />
        </div>
      </div>

      <div class="clients__list">
        <list-loading v-if="isLoading" />
        <template v-else>
          <div v-if="!media.isMobile" class="d-flex align-center">
            <search-bar
              :key="$route.name"
              v-model="queryModel"
              class="mb-6"
              :placeholder="$t('label.client_search')"
              single-line
              full-width
              hide-details
            />
            <clients-filters v-model="tableFilter" :type-client="typeClient" />
          </div>

          <clients-mobile-list
            v-if="media.isMobile"
            v-model="selected"
            :items="clients"
            :type-client="typeClient"
            @open="openDetailed"
          />

          <base-table
            v-else
            v-model="selected"
            class="clients__table data-table"
            hide-default-footer
            show-select
            checkbox-color="primary"
            :items="clients"
            :item-class="rowClass"
            :headers="clientsHeaders"
            :loading="tableLoading"
            :options.sync="options"
            :server-items-length="clientsLimit"
            @click:row="openDetailed"
          >
            <template v-slot:[`header.data-table-select`]="{ props, on }">
              <v-menu
                v-if="!media.isMobile"
                v-model="dialogIsOpen"
                :close-on-content-click="false"
                offset-y
                :value="true"
              >
                <template #activator="{ on: modalActivator }">
                  <v-simple-checkbox
                    v-bind="props"
                    :ripple="false"
                    @click="selectAll(props, modalActivator)"
                    v-on="on"
                  />
                </template>
                <v-card max-width="352">
                  <v-card-text class="text-subtitle-2">
                    {{ $t('clients.select_info') }}
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn text class="font-weight-bold" color="primary" @click="selectAllInPage"
                      >{{ $t('button.all_in_page') }} ({{ pageClientsCount }})</v-btn
                    >
                    <v-btn text class="font-weight-bold" color="primary" @click="selectGlobal"
                      >{{ $t('button.all') }} ({{ clientsCount }})</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-menu>
            </template>
            <template v-slot:header.status>
              <v-icon color="blue50" dark>
                mdi-help-circle-outline
              </v-icon>
            </template>
            <!-- <template v-if="isShortTenantsType" v-slot:item.periodDate="{ item }">
              {{ item.periodDate ? dateDoubleFormat(item.periodDate) : '' }}
            </template> -->
            <template v-slot:item.status="{ item }">
              <v-tooltip right @click.prevent>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon :color="$options.ROWS_CONFIG[item.status].color" dark v-bind="attrs" v-on="on">
                    {{ $options.ROWS_CONFIG[item.status].icon }}
                  </v-icon>
                </template>
                <span>{{ $t(`client.${item.status}`) }}</span>
              </v-tooltip>
            </template>

            <template v-slot:item.projectName="{ item }">
              {{ item.projectName }}
              <span class="planned-bookings">{{ item.plannedProjectName }}</span>
            </template>

            <template v-slot:item.buildingName="{ item }">
              {{ item.buildingName }}
              <span class="planned-bookings">{{ item.plannedBuildingName }}</span>
            </template>

            <template v-slot:item.unitName="{ item }">
              {{ item.unitName }}
              <span class="planned-bookings">{{ item.plannedUnitName }}</span>
            </template>

            <template v-slot:item.roomName="{ item }">
              {{ item.roomName }}
              <span class="planned-bookings">{{ item.plannedRoomName }}</span>
            </template>

            <template v-slot:item.fullName="{ item }">
              <p class="mb-0" style="max-width: 200px;">
                {{ item.fullName }}
              </p>
            </template>
          </base-table>
        </template>
      </div>

      <base-pagination v-if="pageCount && !isLoading" v-model="pageModel" :length="pageCount" class="mt-10" />
    </template>
  </div>
</template>

<script>
// Http
import client from '@/http/client';

import { mapGetters } from 'vuex';
import { COMPANY_SECTIONS_RIGHTS } from '@/store/modules/user/types';

// Constants
import { CLIENTS, CLIENTS_CREATE, CLIENTS_TENANTS, CLIENTS_DETAILED } from '@/constants/routes';
import { CLIENTS as CLIENTS_SUBJECT } from '@/constants/subjects';
import { UPDATE } from '@/constants/actions';
import * as entityStatuses from '@/constants/entityStatuses';
import { CLIENTS_LIST, ARCHIVE_CLIENT, UNARCHIVE_CLIENT } from '@/constants/analyticsActions';
import { IN_PROCESS, READY } from '@/constants/importStatuses';
import * as typesClient from '@/constants/typesClients';

// Utils
import { throttle, retry } from '@/utils/delay';
import { camelToSnake } from '@/utils/formatters';
import { flushPromises } from '@/utils/scheduler';
import joinWithLimit from '@/utils/joinWithLimit';
import { dateDoubleFormat } from '@/utils/dateFormatting';

// Services
import usersService from '@/services/users';
import analyticsService from '@/services/analytics';
import notificationService from '@/services/notification';

// Components
import EntityStatusList from '@/components/EntityStatusList.vue';
import ClientsMenu from '@/components/Clients/Menu.vue';
import ClientsImportMenu from '@/components/Clients/ImportMenu.vue';
import ImportStatus from '@/components/ImportStatus.vue';
import IconButton from '@/components/IconButton.vue';
import ClientsModalFilters from '@/components/Clients/ModalFilters.vue';
import ClientsFilters from '@/components/Clients/Filters.vue';

import MobileSort from '@/components/MobileSort/index.vue';
import MobileSearchMenu from '@/components/MobileSearchMenu.vue';
import ListLoading from '@/components/ListLoading.vue';
import ClientsMobileList from '@/components/Clients/MobileList.vue';
import ClientsMissingData from '@/components/Clients/MissingData.vue';
import BasePagination from '@/components/BasePagination.vue';
import BaseTable from '@/components/BaseTable.vue';
import SearchBar from '@/components/SearchBar.vue';

import InfoModal from '@/components/InfoModal.vue';

export default {
  name: 'Clients',

  components: {
    EntityStatusList,
    ClientsMenu,
    ClientsImportMenu,
    ImportStatus,
    IconButton,
    ClientsModalFilters,
    ClientsFilters,

    MobileSort,
    MobileSearchMenu,
    ListLoading,
    ClientsMobileList,
    ClientsMissingData,
    BasePagination,
    BaseTable,
    SearchBar,

    InfoModal,
  },

  inject: ['media'],

  props: {
    typeClient: { type: String, required: true },
  },

  data() {
    return {
      query: '',
      page: +this.$route.query.page || 1,
      pageCount: 1,
      clientsLimit: 10,
      clientsCount: 0,
      clients: [],
      isLoading: false,
      tableLoading: false,
      isDataMissing: false,
      selected: [],
      globalSelect: false,
      dialogIsOpen: false,
      options: { sortBy: '', sortDesc: null },
      importStatus: {},
      errorDocumentName: 'clients_errors.xlsx',
      tableFilter: {
        statuses: ['active', 'archived', 'unconfirmed', 'invited'],
        date: null,
      },
      showMobileFilter: false,
      sendMessagesAfterMounted: true,
      modal: {
        show: false,
        message: '',
        info: false,
      },
      isImportAddress: false,
    };
  },

  computed: {
    entityClientTypes() {
      return [
        { id: 0, text: this.$t('clients.owners'), name: CLIENTS },
        { id: 1, text: this.$t('clients.tenants'), name: CLIENTS_TENANTS },
        // { id: 2, text: this.$t('clients.short_tenants'), name: CLIENTS_SHORT_TENANTS },
      ];
    },

    statusFetch() {
      return this.tableFilter.statuses.join();
    },

    filterDate() {
      let after = '';
      let before = '';
      if (this.tableFilter.date) {
        const dates = this.tableFilter.date.split(' - ');
        [after, before] = dates;
      }
      return { after, before };
    },

    isShortTenantsType() {
      return this.typeClient === typesClient.SHORT_TENANTS;
    },

    canDisplayActions() {
      return (!this.media.isMobile && this.canUpdateClients) || this.isDataMissing;
    },

    canUpdateClients() {
      return this.$can(UPDATE, CLIENTS_SUBJECT);
    },
    isProcessing() {
      return this.importStatus?.status === IN_PROCESS;
    },

    clientRoute() {
      return { name: CLIENTS_CREATE };
    },

    pageClientsCount() {
      return this.clients.length;
    },

    orderBy() {
      if (!this.options.sortBy || this.options.sortDesc === null) return undefined;

      const header = this.clientsHeaders.find(clientHeader => clientHeader.value === this.options.sortBy);

      const value = camelToSnake(header?.sortValue || this.options.sortBy);

      if (this.options.sortDesc === null) return value;
      return this.options.sortDesc ? `-${value}` : value;
    },

    clientsHeaders() {
      // let headers;
      // if (this.isShortTenantsType) {
      //   headers = [
      //     {
      //       sortText: this.$t('sort_by.client'),
      //       text: this.$t('client.client'),
      //       value: 'fullName',
      //       sortable: true,
      //       width: '17%',
      //       sortValue: 'firstName',
      //     },
      //     { text: this.$t('client.project'), value: 'projectName', width: '17%' },
      //     { text: this.$t('client.building'), value: 'buildingName', width: '14%' },
      //     { text: this.$t('client.unit'), value: 'unitName', width: '14%' },
      //     {
      //       text: this.$t('client.date_contract'),
      //       width: '20%',
      //       sortable: true,
      //       value: 'periodDate',
      //     },

      //     {
      //       text: this.$t('client.rental_price'),
      //       width: '13%',
      //       sortable: true,
      //       value: 'totalRentCost',
      //     },
      //     {
      //       sortable: false,
      //       text: this.$t('client.status'),
      //       value: 'status',
      //     },
      //   ];
      // }
      const headers = [
        {
          sortText: this.$t('sort_by.client'),
          text: this.$t('client.client'),
          value: 'fullName',
          sortable: true,
          width: '23%',
          sortValue: 'firstName',
        },
        { text: this.$t('client.project'), value: 'projectName', width: '17%', sortable: false },
        { text: this.$t('client.building'), value: 'buildingName', width: '14%', sortable: false },
        { text: this.$t('client.unit'), value: 'unitName', width: '14%', sortable: false },
        { text: this.$t('client.room'), value: 'roomName', width: '14%', sortable: false },
        {
          sortText: this.$t('sort_by.email'),
          text: this.$t('client.email'),
          width: '23%',
          sortable: true,
          value: 'email',
        },
        {
          sortable: false,
          text: this.$t('client.status'),
          value: 'status',
        },
      ];

      if (!this.accessToRooms) {
        return headers.filter(item => item.value !== 'roomName');
      }

      return headers;
    },

    activeHeader() {
      return this.clientsHeaders.find(
        header => header.value === this.options.sortBy || header?.sortValue === this.options.sortBy
      );
    },

    pageModel: {
      get() {
        return this.page;
      },

      set(value) {
        this.$router.push({
          name: this.$route.name,
          query: { page: value },
        });
      },
    },

    queryModel: {
      get() {
        return this.query;
      },

      set(value) {
        this.query = value;
      },
    },

    offset() {
      return (this.page - 1) * this.clientsLimit;
    },

    ...mapGetters('user', {
      companySections: COMPANY_SECTIONS_RIGHTS,
    }),

    accessToRooms() {
      return !!this.companySections.find(section => section.name === 'rooms');
    },
  },

  watch: {
    tableFilter: {
      handler() {
        this.page = 1;
        this.getClients();
      },
      deep: true,
    },

    typeClient() {
      if (this.pageModel === 1) {
        if (this.isDataMissing) {
          this.isLoading = true;
          this.isDataMissing = false;
        }

        this.query = '';

        this.getClients()
          .then(() => {
            if (this.clients.length === 0) {
              this.isDataMissing = true;
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.pageModel = 1;
      }

      this.selected = [];
      this.globalSelect = false;
    },

    page() {
      this.selected = [];
      this.globalSelect = false;
      this.getClients();
    },

    selected: {
      handler(newValue) {
        this.$emit('update:selected', newValue);

        if (newValue.length < this.pageClientsCount) {
          this.globalSelect = false;
        }
      },
      immediate: true,
    },

    orderBy() {
      this.getClients();
    },

    // eslint-disable-next-line
    '$route.query.page': function(newValue, oldValue) {
      if (newValue === oldValue) return;
      this.page = +newValue || 1;
    },

    queryModel(newValue, oldValue) {
      if (newValue === oldValue) return;
      this.pageModel = 1;
      this.$options.throttledSearch();
    },
  },

  mounted() {
    analyticsService.track(CLIENTS_LIST);
    this.isLoading = true;

    if (this.$route.query.page === undefined)
      this.$router.push({
        path: this.$route.path,
        query: { page: this.pageModel },
      });

    this.getClients()
      .then(() => {
        if (this.clients.length === 0 && !this.query) {
          this.isDataMissing = true;
        }
      })
      .finally(() => {
        this.isLoading = false;
      });
  },

  beforeMount() {
    this.$options.throttledSearch = throttle(() => {
      this.getClients();
    }, 500);
  },

  methods: {
    selectAllInPage() {
      this.globalSelect = false;
      this.closeSelectDialog();
    },

    selectGlobal() {
      this.globalSelect = true;
      this.closeSelectDialog();
    },

    selectAll(props) {
      if (this.media.isMobile) {
        this.selected = this.clients;

        if (this.pageCount > 1) {
          this.openSelectDialog();
        }

        return;
      }

      if (!props?.value && this.pageCount > 1) {
        this.openSelectDialog();
      }
    },

    closeModal() {
      this.modal.show = false;
      this.modal.message = '';
      this.modal.info = false;
    },
    closeSelectDialog() {
      if (!this.media.isMobile) {
        this.dialogIsOpen = false;
      } else {
        this.closeModal();
      }
    },

    openSelectDialog() {
      if (!this.media.isMobile) {
        this.dialogIsOpen = true;
      } else {
        this.modal.message = this.$t('clients.select_info');
        this.modal.show = true;
      }
    },

    archiveClients() {
      analyticsService.track(ARCHIVE_CLIENT);
      const options = { isArchived: true, clients: this.selected.map(usage => usage.id) };

      const archivePromise = this.globalSelect
        ? usersService.archiveAllClients(options)
        : usersService.archiveClients(options);

      archivePromise
        .then(() => {
          this.showModal(this.$t('client.archive_success'));
          this.getClients();
        })
        .catch(() => {
          this.showModal(this.$t('client.archive_fail'));
        });
    },

    unarchive() {
      analyticsService.track(UNARCHIVE_CLIENT);
      const options = { isArchived: false, clients: this.selected.map(usage => usage.id) };

      const archivePromise = this.globalSelect
        ? usersService.archiveAllClients(options)
        : usersService.archiveClients(options);

      archivePromise
        .then(() => {
          this.showModal(this.$t('client.archive_success'));
          this.getClients();
        })
        .catch(() => {
          this.showModal(this.$t('client.archive_fail'));
        });
    },

    resentEmail() {
      const options = { clients: this.selected.map(usage => usage.id) };

      const resentEmailPromise = this.globalSelect
        ? usersService.passwordResetMailAll({ clientType: this.typeClient })
        : usersService.passwordResetMail(options);

      resentEmailPromise
        .then(() => {
          this.getClients();
          this.showModal(this.$t('client.send_success'));
        })
        .catch(() => {
          this.showModal(this.$t('client.send_fail'));
        });
    },

    sendEmail() {
      const options = { clients: this.selected.map(usage => usage.id) };

      const resentEmailPromise = this.globalSelect
        ? usersService.passwordSendMailAll({ clientType: this.typeClient }, { status: this.statusFetch })
        : usersService.passwordSendMail(options);

      resentEmailPromise
        .then(() => {
          this.getClients();
          this.showModal(this.$t('client.resent_success'));
        })
        .catch(() => {
          this.showModal(this.$t('client.resent_fail'));
        });
    },

    async getClients() {
      if (this.$options.cancelRequestNewClients) {
        this.$options.cancelRequestNewClients();
        await flushPromises();
      }

      this.selected = [];
      this.tableLoading = true;

      try {
        const cancelSource = client.getCancelToken();
        this.$options.cancelRequestNewClients = cancelSource.cancel;

        const data = await this.$options.GET_CLIENTS_CONFIG[this.typeClient](
          {
            limit: this.clientsLimit,
            offset: this.offset,
            status: this.statusFetch,
            search: this.query,
            orderBy: this.orderBy,
            rentalPeriodAfter: this.filterDate.after,
            rentalPeriodBefore: this.filterDate.before,
          },
          {
            cancelToken: cancelSource.token,
          }
        );
        const { count, results } = data;

        this.pageCount = Math.ceil(count / this.clientsLimit);

        this.clientsCount = count;

        this.clients = results.map(usage => this.normalizeData(usage));
      } finally {
        this.tableLoading = false;
        this.$options.cancelRequestNewClients = null;
      }
    },

    openDetailed(item) {
      this.$router.push({ name: CLIENTS_DETAILED, params: { id: item.id } });
    },

    async checkImportClients(files) {
      if (this.isShortTenantsType) {
        await this.importClientsShortTenants(files);
        return;
      }
      await this.importClients(files);
    },

    async checkImportAddresses(files) {
      await this.importAddresses(files);
    },

    async importClients(files) {
      const clients = files[0];
      const status = await this.getImportStatus();

      if (status === IN_PROCESS) {
        this.showModal(this.$t('import.process_try_again'));
        return;
      }

      usersService
        .importClients(clients)
        .then(() => {
          this.importStatus.status = IN_PROCESS;
        })
        .finally(() => {
          this.fetchImportStatus();
        });
    },

    async importAddresses(files) {
      this.isImportAddress = true;
      const addresses = files[0];
      const status = await this.getImportStatusAddresses();

      if (status === IN_PROCESS) {
        this.showModal(this.$t('import.process_try_again'));
        return;
      }

      usersService
        .importAddresses(addresses)
        .then(() => {
          this.importStatus.status = IN_PROCESS;
        })
        .finally(() => {
          this.fetchImportStatus();
        });
    },

    async importClientsShortTenants(files) {
      const clients = files[0];
      const status = await this.getImportStatusShortTenants();

      if (status === IN_PROCESS) {
        this.showModal(this.$t('import.process_try_again'));
        return;
      }

      usersService
        .importShortTermTenantsClients(clients)
        .then(() => {
          this.importStatus.status = IN_PROCESS;
        })
        .finally(() => {
          this.fetchImportStatus();
        });
    },

    async getImportStatus() {
      const { status } = await usersService.getImportStatus();

      return status;
    },

    async getImportStatusAddresses() {
      const { status } = await usersService.getImportStatusAddresses();

      return status;
    },

    async getImportStatusShortTenants() {
      const { status } = await usersService.getImportStatusShortTenants();

      return status;
    },

    // eslint-disable-next-line func-names
    fetchImportStatus: retry(async function() {
      const importStatusPromise = this.isImportAddress
        ? usersService.getImportStatusAddresses
        : usersService.getImportStatus;
      const uploadState = await importStatusPromise();
      this.importStatus = uploadState;

      if (uploadState.status === READY && !this.sendMessagesAfterMounted) {
        this.getClients();
        notificationService.success(this.$t('import.upload_completed_successfully'), 2000);
      }

      this.sendMessagesAfterMounted = false;

      this.isImportAddress = false;

      return uploadState.status === IN_PROCESS;
    }),

    normalizeData(usage) {
      // if (this.isShortTenantsType) {
      //   return {
      //     id: usage.id,
      //     fullName: `${usage.firstName} ${usage.lastName}`,
      //     projectName: usage.project.name,
      //     buildingName: usage.building.name,
      //     unitName: usage.unit.name,
      //     periodDate: usage.rentalPeriodStartDate
      //       ? `${usage.rentalPeriodStartDate} - ${usage.rentalPeriodEndDate}`
      //       : '-',
      //     totalRentCost: usage.totalRentCost
      //       ? `${separateThouthands(usage.totalRentCost)} ${this.$t('invoice.euro')}`
      //       : '—',
      //     status: usage.status,
      //   };
      // }

      const units = [];
      const rooms = [];
      const plannedUnits = [];
      const plannedRooms = [];

      usage.units.forEach(unit => {
        if (unit.parentUnit) {
          units.push({
            id: unit.parentUnit,
            name: unit.parentUnitName,
          });

          rooms.push({
            id: unit.id,
            name: unit.name,
          });
        } else {
          units.push({
            id: unit.id,
            name: unit.name,
          });
        }
      });

      usage.plannedUnits.forEach(unit => {
        if (unit.parentUnit) {
          plannedUnits.push({
            id: unit.parentUnit,
            name: unit.parentUnitName,
          });

          plannedRooms.push({
            id: unit.id,
            name: unit.name,
          });
        } else {
          plannedUnits.push({
            id: unit.id,
            name: unit.name,
          });
        }
      });

      return {
        id: usage.id,
        fullName: `${usage.firstName} ${usage.lastName}`,
        projectName: this.joinWithLimit(usage.projects, 1).trim(),
        plannedProjectName: this.joinWithLimit(usage.plannedProjects, usage.projects.length ? 0 : 1).trim(),
        buildingName: this.joinWithLimit(usage.buildings, 1).trim(),
        plannedBuildingName: this.joinWithLimit(usage.plannedBuildings, usage.buildings.length ? 0 : 1).trim(),
        unitName: this.joinWithLimit(units, 1).trim(),
        plannedUnitName: this.joinWithLimit(plannedUnits, units.length ? 0 : 1).trim(),
        roomName: this.joinWithLimit(rooms, 1).trim(),
        plannedRoomName: this.joinWithLimit(plannedRooms, rooms.length ? 0 : 1).trim(),
        email: usage.email,
        status: usage.status,
      };
    },

    exportClients() {
      usersService.downloadClients('clients.xlsx');
    },

    rowClass(item) {
      return this.$options.ROWS_CONFIG[item.status].class;
    },

    showModalFilter() {
      this.showMobileFilter = true;
    },
    showModal(message) {
      this.modal.message = message;
      this.modal.show = true;
      this.modal.info = true;
    },

    joinWithLimit,
    dateDoubleFormat,
  },

  ROWS_CONFIG: {
    [entityStatuses.ARCHIVED]: { icon: 'mdi-account-cancel', color: 'grey', class: 'clients__row-archive' },
    [entityStatuses.ACTIVE]: { icon: 'mdi-account-check', color: 'blue', class: '' },
    [entityStatuses.UNCONFIRMED]: { icon: 'mdi-account-question', color: 'blue50', class: 'clients__row-unconfirmed' },
    [entityStatuses.INVITED]: { icon: 'mdi-email', color: 'blue40', class: 'clients__row-invited' },
  },

  GET_CLIENTS_CONFIG: {
    [typesClient.SHORT_TENANTS]: usersService.getClientsShortTenants,
    [typesClient.OWNER]: usersService.getClientsOwner,
    [typesClient.TENANTS]: usersService.getClientsTenants,
  },
  cancelRequestNewClients: null,
};
</script>

<style lang="scss">
.clients {
  height: 100%;
  &__row-archive {
    color: $--grey-color-0;
  }
  &__row-unconfirmed {
    color: $--blue-color-35;
  }

  &__row-invited {
    color: $--blue-color-30;
  }

  &__missing-data {
    height: 100%;
  }

  &__list {
    min-height: 455px;
  }

  .planned-bookings {
    color: rgba(22, 56, 207, 0.5);
  }
}
</style>
